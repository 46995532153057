<template>
	<div class="setup-entities">
		<p class="wizard-description">Entities are interactable or scripted items within your metaverse</p>
		Entities:



		<EntityListMenu
			v-if="!active"
			@select="({ item_id }) => active = item_id"
		></EntityListMenu>

		<EntityMenu
			v-else
			:meta="active"
			@select="({ item_id }) => active = item_id"
		></EntityMenu>


		<div v-if="!active" class="mt-4">
			<button :disabled="!canGoNext" v-on:click="goNext" class="btn btn-primary btn-block">Next</button>
		</div>
	</div>
</template>

<script>
import EntityListMenu from "../editor/menus/EntityListMenu";
import EntityMenu from "../editor/menus/EntityMenu";

export default {
	name: "SetupEntities",
	components: {
		EntityMenu,
		EntityListMenu
	},
	data() {
		return {
			active: null,
			form: [],
		}
	},
	computed: {
		canGoNext() {
			return true;
		}
	},
	methods: {
		goNext() {
			if (!this.canGoNext)
				return

			this.$emit('next')
		}
	},
}
</script>

<style scoped>

</style>
