var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "setup-terrain" },
    [
      _c("p", { staticClass: "wizard-description" }, [
        _vm._v(
          "Upload terrain & static scenery here (Note: Terrain cannot be used as an entity later\n\t\ton)"
        ),
      ]),
      _vm._v("\n\tTerrain:\n\n\t"),
      _vm.active === null
        ? _c("TerrainListMenu", {
            on: { select: ({ item_id }) => (_vm.active = item_id) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.active !== null
        ? _c("TerrainMenu", {
            attrs: { meta: _vm.active },
            on: { select: ({ item_id }) => (_vm.active = item_id) },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.active
        ? _c("div", { staticClass: "mt-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-block",
                attrs: { disabled: !_vm.canGoNext },
                on: { click: _vm.goNext },
              },
              [_vm._v("Next")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }