var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select-characters" }, [
    _c("p", { staticClass: "wizard-description" }, [
      _vm._v(
        "Setup how your users interact with your metaverse through control layouts & player\n\t\t\tsprites"
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        !_vm.active
          ? _c("CharacterListMenu", {
              on: { select: ({ item_id }) => (_vm.active = item_id) },
            })
          : _c("CharacterMenu", {
              attrs: { meta: _vm.active },
              on: { select: ({ item_id }) => (_vm.active = item_id) },
            }),
      ],
      1
    ),
    _vm._v(" "),
    !_vm.active
      ? _c("div", { staticClass: "mt-4" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-block",
              attrs: { disabled: !_vm.canGoNext },
              on: { click: _vm.goNext },
            },
            [_vm._v("Next")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }