<template>
	<div class="create-metaverse">

		<div class="card p-0">
			<div class="card-body p-0">
				<div class="row text-center">
					<div class="col" v-for="(step,i) in steps" :key="i"
							v-if="isCustom ? !step.templateOnly : step.nextTemplateStep > 0">
						<!--				<i @click="currentStep > i ? currentStep = i : null" :class="`step-info ${currentStep > i ? 'complete' : ''} ${currentStep === i ? 'active' : ''}`">{{i}}</i>-->

						<div class="step-info"
								@click="(e) => currentStep >= parseInt(i) ? currentStep = parseInt(i) : null"
								:class="`wow fadeInDown ${currentStep > i ? 'complete' : ''} ${currentStep === parseInt(i) ? 'active' : ''}`" data-animation-delay="0.3s"><span
								class="circle">{{ xi[i] }}</span>
							<p class="text wow fadeIn mt-1 mb-0">{{ step.name }}</p></div>
					</div>
				</div>
			</div>
		</div>


		<div class="card mt-3">
			<div class="card-body">
				<div class="wrapper">
					<div class="stepper">

						<Welcome
								v-if="currentStep === 99"
								@next="onNext"
								@mode="onModeChange"
								class="step"
						></Welcome>

						<SetupMode
								v-if="currentStep === 100"
								@next="onNext"
								@mode="onModeChange"
								class="step"
						></SetupMode>

						<SelectTemplate
								v-if="currentStep === 101"
								@next="onNext"
								class="step"
						></SelectTemplate>

						<Settings
								v-if="currentStep === 102"
								@next="onNext"
								class="step"
						></Settings>

						<SetupTerrain
								v-if="currentStep === 103"
								@next="onNext"
								class="step"
						></SetupTerrain>

						<UploadModels
								v-if="currentStep === 104"
								@next="onNext"
								class="step"
						></UploadModels>

						<SetupEntities
								v-if="currentStep === 105"
								@next="onNext"
								class="step"
						></SetupEntities>

						<SelectCharacters
								v-if="currentStep === 106"
								@next="onNext"
								class="step"
						></SelectCharacters>

						<SetupEnvironment
								v-if="currentStep === 107"
								@next="onNext"
								class="step"
						></SetupEnvironment>

						<SetupLighting
								v-if="currentStep === 108"
								@next="onNext"
								class="step"
						></SetupLighting>

						<SetupSound
								v-if="currentStep === 109"
								@next="onNext"
								class="step"
						></SetupSound>

						<BuildingSelect
								v-if="currentStep === 110"
								@next="onBuildingChange"
								class="step"
						></BuildingSelect>

						<!--				<AccessConditions :manifestData="form.authentication" @next="onNext" v-if="currentStep === 10"-->
						<!--													class="step"></AccessConditions>-->

						<FinalStep
								:active-building="activeBuilding"
								v-if="currentStep === 111"
								@next="onFinish"
								class="step"
						></FinalStep>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>

import AccessConditions from "./AccessConditions";
import SelectCharacters from "./SelectCharacters";
import SelectTemplate from "./SelectTemplate";
import SetupEnvironment from "./SetupEnvironment";
import UploadModels from "./UploadModels";
import SetupMode from "./SetupMode";
import Settings from "./Settings";
import SetupTerrain from "./SetupTerrain";
import SetupEntities from "./SetupEntities";
import SetupSound from "./SetupSound";
import SetupLighting from "./SetupLighting";
import FinalStep from "./FinalStep";
import BuildingSelect from "./BuildingSelect";
import Welcome from "./Welcome";

export default {
	name: "CreateMetaverseWizard",
	components: {
		Welcome,
		BuildingSelect,
		FinalStep,
		SetupLighting,
		SetupSound,
		SetupEntities,
		SetupTerrain,
		Settings,
		SetupMode,
		UploadModels,
		AccessConditions,
		SelectCharacters,
		SelectTemplate,
		SetupEnvironment
	},
	data() {
		return {
			xi: {
				99: 0,
				101: 1,
				102: 2,
				110: 3
			},
			isCustom: false,
			currentStep: 99,
			steps: {
				99: {
					name: "Welcome",
					templateOnly: false,
					nextTemplateStep: 101,
					nextScratchStep: 102,
				},
				100: {
					name: "Build Mode",
					templateOnly: false,
					nextTemplateStep: 0,
					nextScratchStep: 102,
				},
				101: {
					name: "Template",
					templateOnly: true,
					nextTemplateStep: 102,
					nextScratchStep: 102,
				},
				102: {
					name: "Settings",
					templateOnly: false,
					nextTemplateStep: 110,
					nextScratchStep: 103,
				},
				103: {
					name: "Terrain",
					templateOnly: false,
					nextTemplateStep: 0,
					nextScratchStep: 104,
				},
				104: {
					name: "Models",
					templateOnly: false,
					nextTemplateStep: 0,
					nextScratchStep: 105,
				},
				105: {
					name: "Entities",
					templateOnly: false,
					nextTemplateStep: 0,
					nextScratchStep: 106,
				},
				106: {
					name: "Characters",
					templateOnly: false,
					nextTemplateStep: 0,
					nextScratchStep: 107,
				},
				107: {
					name: "Environment",
					templateOnly: false,
					nextTemplateStep: 0,
					nextScratchStep: 108,
				},
				108: {
					name: "Lighting",
					templateOnly: false,
					nextTemplateStep: 0,
					nextScratchStep: 109,
				},
				109: {
					name: "Sound",
					templateOnly: false,
					nextTemplateStep: 0,
					nextScratchStep: 111,
				},
				110: {
					name: "Location",
					templateOnly: false,
					nextTemplateStep: 111,
					nextScratchStep: 111,
				},
			},
			activeBuilding: "",
			form: {
				settings: {},
				authentication: {},
				environment: {
					shadows: true,
					shadow_quality: "medium",
					fog: {
						start: 200,
						end: 500,
					},
					skybox: {},
					background: '#fffff',
					spawn_point: [0, 0, 0],
				},
				models: {},
				characters: [],
				sound: {},
				lighting: [],
				terrain: [],
				entities: [],
				voxels: [], //TODO Tab?
				textures: {}, //TODO Tab?
				actions: {} //TODO Tab?
			},
		}
	},
	computed: {
		//
	},
	methods: {
		onNext(data = {}) {
			if (data.isCustom)
				this.isCustom = true;

			this.form = Object.assign(this.form, data);
			this.progress();
		},

		onBuildingChange(data) {
			this.activeBuilding = data.building_id;
			this.progress();
		},

		onModeChange(isCustom) {
			this.isCustom = isCustom;
		},

		onBack() {
			this.currentStep--;
		},
		onFinish() {
		},
		progress() {
			if (this.isCustom) {
				this.currentStep = this.steps[this.currentStep].nextScratchStep
				return
			}

			this.currentStep = this.steps[this.currentStep].nextTemplateStep

		}
	},
	beforeMount() {
		this.activeBuilding = this.$route.params.id
	}
}
</script>

<style scoped>
</style>
