<template>
	<div class="setup-mode">
		<p class="wizard-description mb-3">Select how you wish to configure your metaverse.</p>

		<p class="mt-3 mb-0">
			Welcome to the Oasis setup wizard, from here a new metaverse can be created totally from scratch using your own
			assets.
		</p>

		<p class="mb-5">Select the setup mode you wish to use, then click next to get started on creating your new Oasis.</p>

		<div class="row text-center">
			<div class="col-sm-12 col-md-6">
				<div v-on:click="modeChange(false)" :class="`card card2 ${isCustom ? '' : 'active'}`">
					<div class="card-body">
						<h2 class="mb-4">Quick Start</h2>
						<i class="fa fa-palette fa-4x"></i>
						<p class="mb-0 mt-4">Select from a series of room templates</p>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-6 mt-3 mt-md-0">
				<div v-on:click="modeChange(true)" :class="`card card2 ${isCustom ? 'active' : ''}`">
					<div class="card-body">
						<h2 class="mb-4">Build It All <sup class="blue">Advanced</sup></h2>
						<i class="fa fa-paint-brush fa-4x"></i>
						<p class="mb-0 mt-4">Setup all aspects of the space from scratch</p>
					</div>
				</div>
			</div>

		</div>
		<div class="mt-4">
			<button :disabled="!canGoNext" v-on:click="goNext" class="btn btn-primary btn-block">Next</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "SetupMode",
	data() {
		return {
			isCustom: false,
		}
	},
	computed: {
		canGoNext() {
			return true;
		}
	},
	methods: {
		goNext() {
			if (!this.canGoNext)
				return

			this.$emit('next', {isCustom: this.isCustom});
		},
		modeChange(r) {
			this.isCustom = r;
			this.$emit('mode', this.isCustom);
		}
	}
}
</script>