var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "create-metaverse" }, [
    _c("div", { staticClass: "card p-0" }, [
      _c("div", { staticClass: "card-body p-0" }, [
        _c(
          "div",
          { staticClass: "row text-center" },
          _vm._l(_vm.steps, function (step, i) {
            return (
              _vm.isCustom ? !step.templateOnly : step.nextTemplateStep > 0
            )
              ? _c("div", { key: i, staticClass: "col" }, [
                  _c(
                    "div",
                    {
                      staticClass: "step-info",
                      class: `wow fadeInDown ${
                        _vm.currentStep > i ? "complete" : ""
                      } ${_vm.currentStep === parseInt(i) ? "active" : ""}`,
                      attrs: { "data-animation-delay": "0.3s" },
                      on: {
                        click: (e) =>
                          _vm.currentStep >= parseInt(i)
                            ? (_vm.currentStep = parseInt(i))
                            : null,
                      },
                    },
                    [
                      _c("span", { staticClass: "circle" }, [
                        _vm._v(_vm._s(_vm.xi[i])),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text wow fadeIn mt-1 mb-0" }, [
                        _vm._v(_vm._s(step.name)),
                      ]),
                    ]
                  ),
                ])
              : _vm._e()
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card mt-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c(
            "div",
            { staticClass: "stepper" },
            [
              _vm.currentStep === 99
                ? _c("Welcome", {
                    staticClass: "step",
                    on: { next: _vm.onNext, mode: _vm.onModeChange },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 100
                ? _c("SetupMode", {
                    staticClass: "step",
                    on: { next: _vm.onNext, mode: _vm.onModeChange },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 101
                ? _c("SelectTemplate", {
                    staticClass: "step",
                    on: { next: _vm.onNext },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 102
                ? _c("Settings", {
                    staticClass: "step",
                    on: { next: _vm.onNext },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 103
                ? _c("SetupTerrain", {
                    staticClass: "step",
                    on: { next: _vm.onNext },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 104
                ? _c("UploadModels", {
                    staticClass: "step",
                    on: { next: _vm.onNext },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 105
                ? _c("SetupEntities", {
                    staticClass: "step",
                    on: { next: _vm.onNext },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 106
                ? _c("SelectCharacters", {
                    staticClass: "step",
                    on: { next: _vm.onNext },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 107
                ? _c("SetupEnvironment", {
                    staticClass: "step",
                    on: { next: _vm.onNext },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 108
                ? _c("SetupLighting", {
                    staticClass: "step",
                    on: { next: _vm.onNext },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 109
                ? _c("SetupSound", {
                    staticClass: "step",
                    on: { next: _vm.onNext },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 110
                ? _c("BuildingSelect", {
                    staticClass: "step",
                    on: { next: _vm.onBuildingChange },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 111
                ? _c("FinalStep", {
                    staticClass: "step",
                    attrs: { "active-building": _vm.activeBuilding },
                    on: { next: _vm.onFinish },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }