var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wizard settings row" },
    [
      _c("p", { staticClass: "wizard-description" }, [
        _vm._v(
          "To get started we need some basic information on the metaverse & its purpose"
        ),
      ]),
      _vm._v(" "),
      _c("MetaverseSettingsMenu", {
        attrs: { simple: true },
        on: { update: _vm.onUpdate },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "col-8 offset-2" }),
      _vm._v(" "),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-block",
            attrs: { disabled: !_vm.canGoNext },
            on: { click: _vm.goNext },
          },
          [_vm._v("Next")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }