<template>
	<div class="select-template">
		<h1>Select Template</h1>

		<div class="row">

			<p class="wizard-description my-4 col-sm-12 col-lg-8 offset-lg-2">Templates are preset configurations for popular scenes to allow
				users to get started
				quickly. All options can be changed at a later stage if the user wishes.</p>

			<div class="col-sm-12 col-md-6 col-lg-4 mb-3" v-for="(template, key) in templates" :key="key" @click="setActive(key)">

				<div :class="`template ${active === key ? 'active': ''}`">

					<model-viewer v-if="template.data.terrain.length > 0" class="viewer" auto-rotate style="width: 100%" reveal="manual"
							camera-controls seamless-poster :poster="template.preview"
							:src="template.data.terrain[0].scene"></model-viewer>
					<img alt="" class="img" height="150px" v-else :src="template.preview">

					<h4>{{ template.data.settings.name }}</h4>
				</div>
			</div>

		</div>

		<div class="mt-4">
			<button :disabled="!canGoNext" v-on:click="goNext" class="btn btn-primary btn-block">Next</button>
		</div>
	</div>
</template>

<script>
import {mapActions} from 'vuex'

import "@google/model-viewer"
import template1 from "../../assets/templates/template_gallery_1.json"
import template2 from "../../assets/templates/template_2.json"
import template3 from "../../assets/templates/template_3.json"
import blankTemplate from "../../assets/templates/template_blank.json"

export default {
	name: "SelectTemplate",
	data() {
		return {
			active: 0,
			templates: {
				0: {
					preview: "/assets/images/room_floorplans/art_gallery.png",
					data: template1
				},
				1: {
					preview: "/assets/images/room_floorplans/rectangle.png",
					data: template2
				},
				2: {
					preview: "/assets/images/room_floorplans/l_shaped.png",
					data: template3
				},
				3: {
					preview: "/assets/images/room_floorplans/blank.png",
					data: blankTemplate
				},
			},
		}
	},
	computed: {
		canGoNext() {
			return true;
		}
	},
	methods: {
		...mapActions({
			setManifest: 'metaverse/setManifest',
		}),

		setActive(key) {
			this.active = key;
		},
		goNext() {
			if (!this.canGoNext)
				return

			const template = Object.assign({}, this.templates[this.active].data)

			this.setManifest(template)

			this.$emit('next', template)
		}
	},
	mounted() {
		this.form = Object.assign({}, this.manifestData)
	}
}
</script>

<style scoped>
.viewer {
	width: 100%;
}

.template {
	border: 3px solid white;
	border-radius: 9px;
}

.active {
	background: #0a9fb9;
}
</style>
