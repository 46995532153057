var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setup-mode" }, [
    _c("p", { staticClass: "wizard-description mb-3" }, [
      _vm._v("Getting Started"),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "mt-3" }, [
      _vm._v(
        "\n\t\tWelcome to the Oasis setup wizard, from here you can create a new blank metaverse from a template or a totally from scratch.\n\t"
      ),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "mb-5" }, [
      _vm._v(
        "Once you have created your metaverse you will have access to the visual editor to further customise your metaverse"
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary btn-block",
          attrs: { disabled: !_vm.canGoNext },
          on: { click: _vm.goNext },
        },
        [_vm._v("Next")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }