<template>
	<div>
		<input type="checkbox" v-model="isChecked" /><label @click="onChange">Toggle</label>
	</div>
</template>

<script>
export default {
	name: "ToggleSwitch",
	props: {
		value: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isChecked: false,
		}
	},
	methods: {
		onChange: function(e) {
			this.isChecked = !this.isChecked;
			this.$emit('input',this.isChecked)
		}
	},
	mounted() {
		this.isChecked = this.value;
	}
}
</script>

<style scoped>
input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

label {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 25px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 15px;
	height: 15px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

input:checked + label {
	background: #0a9fb9;
}

input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

label:active:after {
	width: 25px;
}
</style>
