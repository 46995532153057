<template>
	<div class="access-conditions">
		<p class="wizard-description">Setup conditional access on who can enter your metaverse</p>
		Access Conditions

<!--		Admins-->
<!--		Moderators-->
<!--		Ownership of NFT required? -->


		<div class="mt-4">
			<button :disabled="!canGoNext" v-on:click="goNext" class="btn btn-primary float-end">Next</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "AccessConditions",
	props: {
		manifestData: {
			type: Object,
			required: true
		}
	},
	computed: {
		canGoNext() {
			return true;
		}
	},
	methods: {
		goNext() {
			if (!this.canGoNext)
				return

			this.$emit('next')
		}
	},
	mounted() {
		this.form = this.manifestData
	}
}
</script>

<style scoped>

</style>