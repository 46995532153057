var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "setup-environment" },
    [
      _c("p", { staticClass: "wizard-description" }, [
        _vm._v("Setup the background, fog & other environmental effects"),
      ]),
      _vm._v(" "),
      _c("EnvironmentMenu"),
      _vm._v(" "),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-block",
            attrs: { disabled: !_vm.canGoNext },
            on: { click: _vm.goNext },
          },
          [_vm._v("Next")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }