var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "setup-entities" },
    [
      _c("p", { staticClass: "wizard-description" }, [
        _vm._v(
          "Entities are interactable or scripted items within your metaverse"
        ),
      ]),
      _vm._v("\n\tEntities:\n\n\n\n\t"),
      !_vm.active
        ? _c("EntityListMenu", {
            on: { select: ({ item_id }) => (_vm.active = item_id) },
          })
        : _c("EntityMenu", {
            attrs: { meta: _vm.active },
            on: { select: ({ item_id }) => (_vm.active = item_id) },
          }),
      _vm._v(" "),
      !_vm.active
        ? _c("div", { staticClass: "mt-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-block",
                attrs: { disabled: !_vm.canGoNext },
                on: { click: _vm.goNext },
              },
              [_vm._v("Next")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }