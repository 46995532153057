<template>
	<div class="setup-lighting">
		<p class="wizard-description">Setup & Add lighting to give your metaverse more of an immersive feel</p>
		Lighting:

		<LightListMenu
			v-if="!active"
			@select="({ item_id }) => active = item_id"
		></LightListMenu>
		<LightingMenu
			v-else
			:meta="active"
			@select="({ item_id }) => active = item_id"
		></LightingMenu>


		<div v-if="!active" class="mt-4">
			<button :disabled="!canGoNext" v-on:click="goNext" class="btn btn-primary btn-block">Next</button>
		</div>
	</div>
</template>

<script>
import LightListMenu from "../editor/menus/LightListMenu";
import LightingMenu from "../editor/menus/LightingMenu";

export default {
	name: "SetupLighting",
	components: {LightingMenu, LightListMenu},
	computed: {
		canGoNext() {
			return true;
		}
	},
	data() {
		return {
			active: null,
			form: [],
		}
	},
	methods: {
		goNext() {
			if (!this.canGoNext)
				return

			this.$emit('next')
		}
	},
	mounted() {
		this.form = this.manifestData
	}
}
</script>

<style scoped>

</style>
