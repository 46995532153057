<template>
	<div class="setup-mode">
		<p class="wizard-description mb-3">Getting Started</p>

		<p class="mt-3">
			Welcome to the Oasis setup wizard, from here you can create a new blank metaverse from a template or a totally from scratch.
		</p>

		<p class="mb-5">Once you have created your metaverse you will have access to the visual editor to further customise your metaverse</p>


		<div class="mt-4">
			<button :disabled="!canGoNext" v-on:click="goNext" class="btn btn-primary btn-block">Next</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "Welcome",
	data() {
		return {
			isCustom: false,
		}
	},
	computed: {
		canGoNext() {
			return true;
		}
	},
	methods: {
		goNext() {
			if (!this.canGoNext)
				return

			this.$emit('next', {isCustom: this.isCustom});
		},
		modeChange(r) {
			this.isCustom = r;
			this.$emit('mode', this.isCustom);
		}
	}
}
</script>