var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "final-step" }, [
    _c("h1", [_vm._v("Finished")]),
    _vm._v(" "),
    _c("p", { staticClass: "wizard-description" }, [
      _vm._v(
        "Your metaverse is configured! After saving you can begin editing & adding to your\n\t\tmetaverse."
      ),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v("Click complete below to create this metaverse within Hourglass!"),
    ]),
    _vm._v(" "),
    !_vm.updated
      ? _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            { staticClass: "col-8 offset-2" },
            [
              !_vm.$store.state.wallet.connected ? _c("MultiWallet") : _vm._e(),
              _vm._v(" "),
              _c(
                "PhantomSign",
                {
                  staticClass: "btn btn-primary btn-block my-3",
                  attrs: { disabled: !_vm.canGoNext || _vm.loading },
                  on: { sign: _vm.onComplete },
                },
                [_vm._v("Create Metaverse")]
              ),
              _vm._v(" "),
              _vm._m(0),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.updated
      ? _c(
          "div",
          { staticClass: "col-12 mt-4" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-primary me-3",
                attrs: { to: `/metaverse/${_vm.manifestID}/edit?debug=true` },
              },
              [
                _c("i", { staticClass: "fa fa-edit" }),
                _vm._v(" Edit Metaverse"),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "btn btn-secondary me-3",
                attrs: { to: `/metaverse/${_vm.manifestID}/visit` },
              },
              [
                _c("i", { staticClass: "fa fa-play" }),
                _vm._v(" Visit Metaverse"),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("hr", { staticClass: "mt-4" }),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm.showManifest
      ? _c("div", { staticClass: "row mt-5 text-left" }, [
          _c("pre", [
            _c("code", [_vm._v(_vm._s(_vm.$store.state.metaverse.manifest))]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mt-3 small" }, [
      _c("i", [_vm._v("There are no costs associated to metaverse creation")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row info" }, [
      _c("p", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Information: ")]),
        _vm._v(
          " During ALPHA stage, Land manifests are stored off chain. Once out of ALPHA these\n\t\t\tcan then be committed onchain at a later date.\n\t\t"
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Support: ")]),
        _vm._v(
          " Having an issue while setting up your metaverse? reach out to us at "
        ),
        _c(
          "a",
          { attrs: { href: "mailto:whitelist-support@alphabatem.com" } },
          [_vm._v("whitelist-support@alphabatem.com")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }