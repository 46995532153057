<template>
	<div class="setup-environment">
		<p class="wizard-description">Setup the background, fog & other environmental effects</p>

		<EnvironmentMenu />

		<div class="mt-4">
			<button :disabled="!canGoNext" v-on:click="goNext" class="btn btn-primary btn-block">Next</button>
		</div>
	</div>
</template>

<script>
import EnvironmentMenu from "../editor/menus/EnvironmentMenu";

export default {
	name: "SetupEnvironment",
	components: {EnvironmentMenu},
	computed: {
		canGoNext() {
			return true;
		}
	},
	data() {
		return {
			form: {}
		}
	},
	methods: {
		onUpdate(data) {
			this.form = data;
			this.goNext();
		},
		goNext() {
			if (!this.canGoNext)
				return

			this.$emit('next')
		}
	},
}
</script>

<style scoped>

</style>
