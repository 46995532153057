<template>
	<div class="setup-terrain">
		<p class="wizard-description">Upload terrain & static scenery here (Note: Terrain cannot be used as an entity later
			on)</p>
		Terrain:

		<TerrainListMenu
			v-if="active === null"
			@select="({ item_id }) => active = item_id"
		></TerrainListMenu>

		<TerrainMenu
			v-if="active !== null"
			:meta="active"
			@select="({ item_id }) => active = item_id"
		></TerrainMenu>

		<div v-if="!active" class="mt-4">
			<button :disabled="!canGoNext" @click="goNext" class="btn btn-primary btn-block">Next</button>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import TerrainListMenu from "../editor/menus/TerrainListMenu"
import TerrainMenu from "../editor/menus/TerrainMenu"

export default Vue.extend({
	name: "SetupTerrain",
	components: {
		TerrainMenu,
		TerrainListMenu
	},
	data() {
		return {
			form: {},
			active: null,
		}
	},
	computed: {
		canGoNext() {
			return true;
		}
	},
	methods: {
		goNext() {
			if (!this.canGoNext)
				return

			this.$emit('next')
		}
	},
})
</script>

<style scoped>

</style>
