var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "access-conditions" }, [
    _c("p", { staticClass: "wizard-description" }, [
      _vm._v("Setup conditional access on who can enter your metaverse"),
    ]),
    _vm._v("\n\t\tAccess Conditions\n\n"),
    _vm._v(" "),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary float-end",
          attrs: { disabled: !_vm.canGoNext },
          on: { click: _vm.goNext },
        },
        [_vm._v("Next")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }