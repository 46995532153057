<template>
	<div class="final-step">
		<h1>Finished</h1>

		<p class="wizard-description">Your metaverse is configured! After saving you can begin editing & adding to your
			metaverse.</p>
		<p>Click complete below to create this metaverse within Hourglass!</p>


		<div class="row mt-4" v-if="!updated">
			<div class="col-8 offset-2">
				<!--				<Phantom class="btn btn-secondary btn-block" v-if="!$store.state.wallet.connected"></Phantom>-->
				<MultiWallet v-if="!$store.state.wallet.connected"></MultiWallet>

				<PhantomSign :disabled="!canGoNext || loading" @sign="onComplete" class="btn btn-primary btn-block my-3">Create Metaverse</PhantomSign>

				<p class="mt-3 small"><i>There are no costs associated to metaverse creation</i></p>
			</div>
		</div>

		<div class="col-12 mt-4" v-if="updated">
			<!--			<button v-on:click="showManifest = !showManifest" class="btn btn-outline-secondary me-3">Show Metadata</button>-->

			<router-link :to="`/metaverse/${manifestID}/edit?debug=true`" class="btn btn-primary me-3"><i class="fa fa-edit"></i> Edit Metaverse</router-link>

			<router-link :to="`/metaverse/${manifestID}/visit`" class="btn btn-secondary me-3"><i class="fa fa-play"></i> Visit Metaverse</router-link>
		</div>

		<hr class="mt-4">
		<div class="row info">
			<p class="col-12">
				<strong>Information: </strong> During ALPHA stage, Land manifests are stored off chain. Once out of ALPHA these
				can then be committed onchain at a later date.
			</p>


			<p class="col-12">
				<strong>Support: </strong> Having an issue while setting up your metaverse? reach out to us at <a
					href="mailto:whitelist-support@alphabatem.com">whitelist-support@alphabatem.com</a>
			</p>
		</div>

		<div class="row mt-5 text-left" v-if="showManifest">
			<pre><code>{{ $store.state.metaverse.manifest }}</code></pre>
		</div>
	</div>
</template>

<script>
import {mapActions} from 'vuex'
import PhantomSign from "../wallet/PhantomSign";
import Phantom from "../wallet/Phantom";
import MultiWallet from "../wallet/MultiWallet";

export default {
	name: "FinalStep",
	components: {MultiWallet, Phantom, PhantomSign},
	props: {
		activeBuilding: {
			type: String,
			required: true,
		}
	},
	data() {
		return {
			loading: false,
			updated: false,
			manifestID: "0x0",
			showManifest: false,
		}
	},
	computed: {
		canGoNext() {
			return true;
		}
	},
	methods: {
		...mapActions({
			createMetaverse: 'metaverse/createMetaverse',
		}),

		async onComplete(signature) {
			const payload = this.buildMetaverseDetails(signature)
			this.loading = true


			this.createMetaverse(payload).then(r => {
				this.manifestID = r.data.uuid;
				this.$toastr.s("Oasis created");

				this.goNext()
			}).catch(e => {
				this.onError(e)
			})
		},

		buildMetaverseDetails(signature) {
			const payload = Object.assign({}, this.$store.state.metaverse.manifest)

			return {
				name: payload.settings.name,
				description: payload.settings.description,
				token_addr: "0x0",
				building_id: this.activeBuilding,
				target_oasis_id: "",
				kyc: payload.settings.nsfw,
				nsfw: payload.settings.nsfw,
				logo_src: payload.settings.logo_src,
				banner_src: payload.settings.banner_src,
				manifest: payload,
				signed: signature
			}
		},
		onError(resp, error) {
			console.log(error);
			this.$toastr.e("Creation error", error);
			this.loading = false
		},
		goNext() {
			if (!this.canGoNext)
				return

			this.updated = true
			this.loading = false
			this.$emit('next');
		}
	}
}
</script>

<style scoped>
.info {
	font-size: 0.7em;
}

.info a {
	color: white;
}

</style>
