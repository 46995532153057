var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "upload-models" }, [
    _c("p", { staticClass: "wizard-description" }, [
      _vm._v("Upload the models you wish to use within your metaverse."),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _vm.active === null
          ? _c("ModelListMenu", {
              staticClass: "col-12",
              on: { select: ({ item_id }) => (_vm.active = item_id) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.active !== null
          ? _c("ModelMenu", {
              attrs: { meta: _vm.active },
              on: { select: ({ item_id }) => (_vm.active = item_id) },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    !_vm.active
      ? _c("div", { staticClass: "mt-4" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-block",
              attrs: { disabled: !_vm.canGoNext },
              on: { click: _vm.goNext },
            },
            [_vm._v("Next")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }