<template>
<div class="setup-sound">
	<p class="wizard-description">Add some sound effects & music to your metaverse</p>
		Sound:

	<SoundListMenu :manifest="{sound: form}"></SoundListMenu>

<!--Movement-->
<!--Voice-->
<!--Chat-->
<!--Ambient_Music-->

	<div class="mt-4">
		<button :disabled="!canGoNext" v-on:click="goNext" class="btn btn-primary btn-block">Next</button>
	</div>
</div>
</template>

<script>
import SoundMenu from "../editor/menus/SoundMenu";
import SoundListMenu from "../editor/menus/SoundListMenu";
export default {
	name: "SetupSound",
	components: {SoundListMenu, SoundMenu},
	data() {
		return {
			form:{}
		}
	},
	computed: {
		canGoNext() {
			return true;
		}
	},
	methods: {
		goNext() {
			if (!this.canGoNext)
				return

			this.$emit('next')
		}
	},
}
</script>

<style scoped>

</style>
