<template>
	<div class="setup-mode">

		<p class="wizard-description mb-3">Select Location <sup class="white small">(Optional)</sup></p>

		<p class="mt-3">Assigning your metaverse to a building allows others to access it via the world map and benefit from building upgrade perks.</p>

		<div class="form-group mt-4">
			<div class="row" v-if="buildings.length > 0">
				<div class="col">
					<select class="form-control form-control-lg text-white" v-model="building_id">
						<option disabled value="">Select Building</option>
						<option v-for="(v,k) in  buildings" :value="v.token_addr" :key="k">Land ({{ v.spawn_chunk_x }},{{ v.spawn_chunk_y }}) - {{ v.name }}</option>
						<!--				<option v-for="(v,k) in  buildings" :value="v.token_addr" :key="k">Land ({{v.spawn_chunk_x}},{{v.spawn_chunk_y}}) - {{v.name}} Capacity: 0/{{models[buildings[0].building_model_id] ?-->
						<!--						models[buildings[0].building_model_id].tiles_required * 10 : '#'}} - -->
						<!--				</option>-->
					</select>
				</div>
				<div class="col-auto" v-if="canGoNext">
					<h4>{{ activeBuilding.name }}</h4>
					<model-viewer auto-rotate :src="activeModel.model_src" :poster="activeModel.image_src" :seamless-poster="true">
						<div slot="poster" class="img-container" :style="{backgroundImage: `url(${activeModel.image_src})`}"></div>
					</model-viewer>
				</div>
			</div>

			<div v-else class="col-sm-12 col-md-8 mx-auto">
				<p class="mt-3">Looks like you are missing some land! Head over to the marketplace to pick some up, or continue without assigning your metaverse to a building</p>

				<div class="text-center mb-3 row">
					<div class="col-sm-6 col-lg-4 offset-sm-0 offset-lg-4">
						<a target="_blank" href="https://hyperspace.xyz/collection/5SMfBS7H47YHC3npgeCaMjQw8FYs4rtuhE2gNYo8S3jY"
								class="btn btn-secondary btn-block">
							<HyperSpaceIcon></HyperSpaceIcon>
							<span>MARKETPLACE</span></a>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-5 row">
			<div class="col">
				<button :disabled="!canGoNext" v-on:click="goNext" class="btn btn-primary btn-block">Next</button>
			</div>
			<div class="col-auto">
				<button :disabled="!canGoNext" v-on:click="goNext" class="btn btn-secondary px-5">Skip</button>
			</div>
		</div>
	</div>
</template>

<script>
import User from "../../js/api/user"
import Building from "../../js/api/building"
import HyperSpaceIcon from "../misc/HyperSpaceIcon";

export default {
	name: "BuildingSelect",
	components: {HyperSpaceIcon},
	data() {
		return {
			isCustom: false,
			building_id: "",
			buildings: [],
			models: {},
		}
	},
	computed: {
		canGoNext() {
			// return this.building_id !== "";
			return this.buildings.length > 0 ? this.building_id !== "" : true;
		},
		activeBuilding() {
			return this.buildings.find((b) => b.token_addr === this.building_id)
		},
		activeModel() {
			if (!this.canGoNext)
				return null

			return this.models[this.activeBuilding.building_model_id]
		}
	},
	methods: {
		getData() {
			User.indexBuildings(this.$store.state.wallet.address).then((r) => this.buildings = r.data);
			Building.indexModels().then(resp => {
				for (const m in resp.data) {
					this.models[resp.data[m].id] = resp.data[m]
				}
				console.log("models:", this.models)
			})
		},
		goNext() {
			if (!this.canGoNext)
				return

			this.$emit('next', {building_id: this.building_id});
		}
	},
	mounted() {
		this.getData()
	}
}
</script>

<style scoped>
.form-control {
	background: transparent;
}

.form-control * {
	background: black;
}
</style>