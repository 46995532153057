var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setup-mode" }, [
    _c("p", { staticClass: "wizard-description mb-3" }, [
      _vm._v("Select how you wish to configure your metaverse."),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "mt-3 mb-0" }, [
      _vm._v(
        "\n\t\tWelcome to the Oasis setup wizard, from here a new metaverse can be created totally from scratch using your own\n\t\tassets.\n\t"
      ),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "mb-5" }, [
      _vm._v(
        "Select the setup mode you wish to use, then click next to get started on creating your new Oasis."
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row text-center" }, [
      _c("div", { staticClass: "col-sm-12 col-md-6" }, [
        _c(
          "div",
          {
            class: `card card2 ${_vm.isCustom ? "" : "active"}`,
            on: {
              click: function ($event) {
                return _vm.modeChange(false)
              },
            },
          },
          [_vm._m(0)]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12 col-md-6 mt-3 mt-md-0" }, [
        _c(
          "div",
          {
            class: `card card2 ${_vm.isCustom ? "active" : ""}`,
            on: {
              click: function ($event) {
                return _vm.modeChange(true)
              },
            },
          },
          [_vm._m(1)]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary btn-block",
          attrs: { disabled: !_vm.canGoNext },
          on: { click: _vm.goNext },
        },
        [_vm._v("Next")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body" }, [
      _c("h2", { staticClass: "mb-4" }, [_vm._v("Quick Start")]),
      _vm._v(" "),
      _c("i", { staticClass: "fa fa-palette fa-4x" }),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0 mt-4" }, [
        _vm._v("Select from a series of room templates"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body" }, [
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v("Build It All "),
        _c("sup", { staticClass: "blue" }, [_vm._v("Advanced")]),
      ]),
      _vm._v(" "),
      _c("i", { staticClass: "fa fa-paint-brush fa-4x" }),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0 mt-4" }, [
        _vm._v("Setup all aspects of the space from scratch"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }