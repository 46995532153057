var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select-template" }, [
    _c("h1", [_vm._v("Select Template")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "p",
          {
            staticClass:
              "wizard-description my-4 col-sm-12 col-lg-8 offset-lg-2",
          },
          [
            _vm._v(
              "Templates are preset configurations for popular scenes to allow\n\t\t\tusers to get started\n\t\t\tquickly. All options can be changed at a later stage if the user wishes."
            ),
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.templates, function (template, key) {
          return _c(
            "div",
            {
              key: key,
              staticClass: "col-sm-12 col-md-6 col-lg-4 mb-3",
              on: {
                click: function ($event) {
                  return _vm.setActive(key)
                },
              },
            },
            [
              _c(
                "div",
                { class: `template ${_vm.active === key ? "active" : ""}` },
                [
                  template.data.terrain.length > 0
                    ? _c("model-viewer", {
                        staticClass: "viewer",
                        staticStyle: { width: "100%" },
                        attrs: {
                          "auto-rotate": "",
                          reveal: "manual",
                          "camera-controls": "",
                          "seamless-poster": "",
                          poster: template.preview,
                          src: template.data.terrain[0].scene,
                        },
                      })
                    : _c("img", {
                        staticClass: "img",
                        attrs: {
                          alt: "",
                          height: "150px",
                          src: template.preview,
                        },
                      }),
                  _vm._v(" "),
                  _c("h4", [_vm._v(_vm._s(template.data.settings.name))]),
                ],
                1
              ),
            ]
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary btn-block",
          attrs: { disabled: !_vm.canGoNext },
          on: { click: _vm.goNext },
        },
        [_vm._v("Next")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }