<template>
	<div class="select-characters">
		<p class="wizard-description">Setup how your users interact with your metaverse through control layouts & player
			sprites</p>

		<div class="row">


			<CharacterListMenu 
				v-if="!active"
				@select="({ item_id }) => active = item_id"
			></CharacterListMenu>
			<CharacterMenu
				v-else
				:meta="active"
				@select="({ item_id }) => active = item_id"
			></CharacterMenu>

<!--			<div class="col-12">-->
<!--				<h5>Movement Style</h5>-->

<!--				<div class="row mt-3">-->
<!--					<div class="col-6">-->
<!--						<div class="card card2">-->
<!--							WASD-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="col-6">-->
<!--						<div class="card card2">-->
<!--							Click-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->

<!--			<div class="col-12">-->
<!--				<h5 class="mt-3">Viewport Style</h5>-->
<!--				<div class="row mt-3">-->
<!--					<div class="col-4">-->
<!--						<div class="card card2">-->
<!--							1st Person-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="col-4">-->
<!--						<div class="card card2">-->
<!--							3rd Person-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="col-4">-->
<!--						<div class="card card2">-->
<!--							User Defined-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->


<!--			<div class="col-12">-->
<!--				<hr>-->
<!--				<h5 class="my-3">Default Sprite <small><i>Define the sprite that guest & new players will use</i></small></h5>-->

<!--				<select v-model="form.model" class="select">-->
<!--					<option :value="null" selected>Default</option>-->
<!--					<option v-for="(model,name) in models" :value="name">{{ name }}</option>-->
<!--					<option disabled>More Coming Soon...</option>-->
<!--				</select>-->

<!--				<h5 class="mt-3">Sprite Scale</h5>-->
<!--				<div class="row mt-3">-->
<!--					<input class="form-control" v-model="form.scale" type="number" min="0.0" step="0.1">-->
<!--				</div>-->

<!--				<div class="mt-4" v-if="form.animations">-->
<!--					<h5 class="mb-3">Animations</h5>-->
<!--					<i>Map the models animation names to the default convention</i>-->

<!--					<div class="row mt-3">-->
<!--						<div class="col-2 text-left">-->
<!--							<label>Walk: </label>-->
<!--						</div>-->
<!--						<div class="col">-->
<!--							<input class="form-control form-control-sm" v-model="form.animations.walk">-->
<!--						</div>-->
<!--					</div>-->

<!--					<div class="row mt-2">-->
<!--						<div class="col-2 text-left">-->
<!--							<label>Idle: </label>-->
<!--						</div>-->
<!--						<div class="col">-->
<!--							<input class="form-control form-control-sm" v-model="form.animations.idle">-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
<!--				</div>-->
		</div>


		<div v-if="!active" class="mt-4">
			<button :disabled="!canGoNext" v-on:click="goNext" class="btn btn-primary btn-block">Next</button>
		</div>
	</div>
</template>

<script>
import "@google/model-viewer"
import CharacterListMenu from "../editor/menus/CharacterListMenu";
import CharacterMenu from "../editor/menus/CharacterMenu";

export default {
	name: "SelectCharacters",
	components: {CharacterMenu, CharacterListMenu},
	data() {
		return {
			active: null,
			form: []
		}
	},
	computed: {
		canGoNext() {
			return true;
		},

		// modelSrc() {
		// 	if (this.form.selectedModel === null)
		// 		return "https://d1a370nemizbjq.cloudfront.net/43380275-56e7-4eba-85ff-4621f977db29.glb"
		//
		// 	return this.models[this.form.selectedModel]
		// }
	},
	methods: {
		goNext() {
			if (!this.canGoNext)
				return

			this.$emit('next')
		}
	},
}
</script>

<style scoped>
h5 {
	text-align: left;
	font-size: 1.2rem;
}

h5 {

	text-align: left;
}

.form-group {
	background: transparent;
	text-align: left;
	border: none;
	padding: 0;
}

.viewer {
	height: 160px;
}
</style>
