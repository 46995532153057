<template>
	<div class="upload-models">
		<p class="wizard-description">Upload the models you wish to use within your metaverse.</p>

		<div class="row">
			<ModelListMenu
				v-if="active === null"
				@select="({ item_id }) => active = item_id"
				class="col-12"
			></ModelListMenu>

			<ModelMenu
				v-if="active !== null"
				:meta="active"
				@select="({ item_id }) => active = item_id"
			></ModelMenu>
		</div>

		<!--		<div class="row">-->
		<!--			<div class="col-6">-->
		<!--				<p>Upload the 3D models you wish to use within your metaverse, we will setup the definitions of these models-->
		<!--					later on</p>-->


		<!--				<p class="blue mt-4">File Type Accepted: GLB</p>-->

		<!--				<p class="mt-4">Free & Paid 3D models can be found online through a variety of vendors</p>-->

		<!--				<div class="row mt-3">-->
		<!--					<div class="col">-->
		<!--						<img alt="TurboSquid" src="/assets/images/turbo_squid.png"></div>-->
		<!--					<div class="col">-->
		<!--						<img alt="Sketchfab" src="/assets/images/sketchfab.svg">-->
		<!--					</div>-->
		<!--				</div>-->
		<!--			</div>-->

		<!--			<div class="col-6">-->
		<!--				<ModelUploader class="bordered" @uploaded="onUpload" endpoint="/auth/users/files"></ModelUploader>-->
		<!--			</div>-->
		<!--		</div>-->

		<!--		<div class="row">-->
		<!--			<hr class="mt-3">-->
		<!--			<h5 class="text-left">Uploaded Files: </h5>-->

		<!--			<div class="mt-5" v-if="filesUploaded.length === 0">-->
		<!--				<i>No models uploaded, drag your models into the box in the top right to get started.</i>-->
		<!--			</div>-->

		<!--			<div class="alert alert-info" v-for="(file,key) in filesUploaded" :key="key">-->
		<!--				<span class="float-start">{{ file.name }}</span> <span>{{ file.status }}</span> <span-->
		<!--					class="float-end">{{ file.size }} Bytes</span>-->
		<!--			</div>-->
		<!--		</div>-->


		<div v-if="!active" class="mt-4">
			<button :disabled="!canGoNext" @click="goNext" class="btn btn-primary btn-block">Next</button>
		</div>
	</div>
</template>

<script>
import {uuidv4} from "@alphabatem/js_sdk/src/common/utils";
import ModelUploader from "../upload/ModelUploader";
import ModelListMenu from "../editor/menus/ModelListMenu";
import ModelMenu from "../editor/menus/ModelMenu";

export default {
	name: "UploadModels",
	components: {
		ModelMenu,
		ModelListMenu,
		ModelUploader
	},
	data() {
		return {
			active: null,
			filesUploaded: [],
			models: {
				//
			},
		}
	},
	computed: {
		canGoNext() {
			return Object.keys(this.models).length > -1;
		}
	},
	methods: {
		goNext() {
			if (!this.canGoNext)
				return

			console.log("next", this.models)
			for (let file of this.filesUploaded) {
				this.models[file.name] = file.fileSrc
			}

			this.$emit('next', {models: this.models})
		},

		onUpload(item, wallet, data) {
			console.log("item uploaded", item, data)

			data.fileSrc = item;
			this.filesUploaded.push(data)
		}
	},
}
</script>

<style scoped>

img {
	height: 25px;
}

h3 {
	text-align: left;
}

.bordered {
	border-radius: 9px;
}

.alert-info {
	font-weight: bold;
	text-transform: uppercase;

	color: white;
	background: transparent;
	border: 1px solid white;
}

.blue {
	font-weight: bold;
	color: #00bcd4;
}
</style>
