var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setup-mode" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("p", { staticClass: "mt-3" }, [
      _vm._v(
        "Assigning your metaverse to a building allows others to access it via the world map and benefit from building upgrade perks."
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group mt-4" }, [
      _vm.buildings.length > 0
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.building_id,
                      expression: "building_id",
                    },
                  ],
                  staticClass: "form-control form-control-lg text-white",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.building_id = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { disabled: "", value: "" } }, [
                    _vm._v("Select Building"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.buildings, function (v, k) {
                    return _c(
                      "option",
                      { key: k, domProps: { value: v.token_addr } },
                      [
                        _vm._v(
                          "Land (" +
                            _vm._s(v.spawn_chunk_x) +
                            "," +
                            _vm._s(v.spawn_chunk_y) +
                            ") - " +
                            _vm._s(v.name)
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _vm.canGoNext
              ? _c(
                  "div",
                  { staticClass: "col-auto" },
                  [
                    _c("h4", [_vm._v(_vm._s(_vm.activeBuilding.name))]),
                    _vm._v(" "),
                    _c(
                      "model-viewer",
                      {
                        attrs: {
                          "auto-rotate": "",
                          src: _vm.activeModel.model_src,
                          poster: _vm.activeModel.image_src,
                          "seamless-poster": true,
                        },
                      },
                      [
                        _c("div", {
                          staticClass: "img-container",
                          style: {
                            backgroundImage: `url(${_vm.activeModel.image_src})`,
                          },
                          attrs: { slot: "poster" },
                          slot: "poster",
                        }),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _c("div", { staticClass: "col-sm-12 col-md-8 mx-auto" }, [
            _c("p", { staticClass: "mt-3" }, [
              _vm._v(
                "Looks like you are missing some land! Head over to the marketplace to pick some up, or continue without assigning your metaverse to a building"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-center mb-3 row" }, [
              _c(
                "div",
                { staticClass: "col-sm-6 col-lg-4 offset-sm-0 offset-lg-4" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-secondary btn-block",
                      attrs: {
                        target: "_blank",
                        href: "https://hyperspace.xyz/collection/5SMfBS7H47YHC3npgeCaMjQw8FYs4rtuhE2gNYo8S3jY",
                      },
                    },
                    [
                      _c("HyperSpaceIcon"),
                      _vm._v(" "),
                      _c("span", [_vm._v("MARKETPLACE")]),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt-5 row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-block",
            attrs: { disabled: !_vm.canGoNext },
            on: { click: _vm.goNext },
          },
          [_vm._v("Next")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-auto" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary px-5",
            attrs: { disabled: !_vm.canGoNext },
            on: { click: _vm.goNext },
          },
          [_vm._v("Skip")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "wizard-description mb-3" }, [
      _vm._v("Select Location "),
      _c("sup", { staticClass: "white small" }, [_vm._v("(Optional)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }