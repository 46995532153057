<template>
	<div class="wizard settings row">
		<p class="wizard-description">To get started we need some basic information on the metaverse & its purpose</p>
		<MetaverseSettingsMenu @update="onUpdate" :simple="true"/>

		<div class="col-8 offset-2">
			<!--			<div class="row my-3">-->
			<!--				<div class="col-8">-->
			<!--					<label>Not Safe For Work (NSFW)</label>-->
			<!--					<p class="info">If enabled, users will be able to create NSFW content but will not be listed to users without KYC verification.</p>-->
			<!--				</div>-->
			<!--				<div class="col">-->
			<!--					<ToggleSwitch class="float-end" :checked="form.settings.nsfw"-->
			<!--												@change="(e) => form.settings.nsfw = e"></ToggleSwitch>-->
			<!--				</div>-->
			<!--			</div>-->


		</div>

		<div class="mt-4">
			<button :disabled="!canGoNext" @click="goNext" class="btn btn-primary btn-block">Next</button>
		</div>
	</div>
</template>

<script>
import MetaverseSettingsMenu from '@/components/editor/menus/MetaverseSettingsMenu';
import ToggleSwitch from "../common/ToggleSwitch";
import ImageUploader from "../upload/ImageUploader";

export default {
	name: "Settings",
	components: {
		MetaverseSettingsMenu,
		ImageUploader,
		ToggleSwitch
	},
	data() {
		return {
			form: {
				settings: {
					name: '',
					description: '',
					banner_src: "",
					logo_src: "",
					nsfw: false,
				},
				authentication: {
					allow_guests: true,
				}
			}
		}
	},
	computed: {
		canGoNext() {
			return this.form.settings.name.length > 3;
		},
	},
	methods: {
		goNext() {
			if (!this.canGoNext)
				return

			this.$emit('next', this.form)
		},
		onBannerUpload(bannerSrc) {
			this.$set(this.form.settings, "banner_src", bannerSrc)
		},
		onLogoUpload(logoSrc) {
			this.$set(this.form.settings, "logo_src", logoSrc)
		},
		removeLogo() {
			this.$set(this.form.settings, "logo_src", "")
		},
		removeBanner() {
			this.$set(this.form.settings, "banner_src", "")
		},
		onUpdate(data) {
			console.log("On update", data)
			this.form.settings = data
		}
	},
	beforeMount() {
		if (this.$store.state.profile.data.name !== '') {
			const newName = this.$store.state.profile.data.name + "'s Room #" + Math.floor(Math.random() * 1000)
			this.$set(this.form.settings, "name", newName)
			this.$store.state.metaverse.manifest.settings.name = newName
		}

		// this.form.settings = this.settings
		let keys = Object.keys(this.form.settings)

		for (let i = 0; i < keys.length; i++) {
			const value = this.settings?.[keys[i]]

			if (value) {
				this.form.settings[keys[i]] = value
			}
		}

		// this.form.authentication = this.authentication
		keys = Object.keys(this.form.authentication)

		for (let i = 0; i < keys.length; i++) {
			const value = this.authentication?.[keys[i]]

			if (value) {
				this.form.authentication[keys[i]] = value
			}
		}
	},
}
</script>

<style scoped>
label, .settings {
	text-align: left;
}


img.logo {
	max-height: 200px;
	margin-left: 5%;
	border-radius: 9px;
}

textarea {
	width: 100%;
}
</style>
